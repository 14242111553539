import React, { useState } from 'react';
import styled from 'styled-components';
import website from '../../images/website.png';
import socialMedia from '../../images/social-media.png';
import branding from '../../images/branding.png';
import computing from '../../images/computing.png';
import { ToastContainer, toast } from 'react-toastify';
import { useStaticQuery, graphql, Link as LinkG } from 'gatsby';

export default function Contact() {
  const data = useStaticQuery(graphql`
    query PrivacyPolicyLink {
      site {
        siteMetadata {
          privacyPolicyLink
          copyright
        }
      }
    }
  `);
  const { privacyPolicyLink } = data.site.siteMetadata;

  const [isPending, setIsPending] = useState(false);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    let myForm = document.getElementById('contactForm');
    let formData = new FormData(myForm);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setIsPending(false);
        toast.success(
          'Ευχαριστούμε για το μήνυμα! Θα επικοινωνήσουμε σύντομα μαζί σας.'
        );
      })
      .catch((error) => toast.error('Έγινε κάποιο λάθος. Δοκιμάστε ξανά.'));
  };

  return (
    <ContactSection id='contact'>
      <div className='section-inner'>
        <h2 className='green-headings'>Επικοινωνια</h2>
        <p>Επιλέξτε λόγο επικοινωνίας</p>
        <ContactForm
          id='contactForm'
          name='contactForm'
          data-netlify='true'
          netlify-honeypot='bot-field'
          onSubmit={handleFormSubmit}
        >
          <input type='hidden' name='form-name' value='contactForm' />
          <label className='hidden'>
            Don’t fill this out if you’re human: <input name='bot-field' />
          </label>
          <ContactFormCheckboxesWrap>
            <label htmlFor='website'>
              <span>
                <img src={website} width='80px' height='80px' alt='' />
              </span>
              <span>Ιστοσελίδα</span>
              <span>
                <input type='checkbox' name='reason' id='website' />
              </span>
            </label>

            <label htmlFor='digital'>
              <span>
                <img src={socialMedia} width='80px' height='80px' alt='' />
              </span>
              <span>Digital Marketing</span>
              <span>
                <input type='checkbox' name='reason' id='digital' />
              </span>
            </label>

            <label htmlFor='branding'>
              <span>
                <img src={branding} width='80px' height='80px' alt='' />
              </span>
              <span>Branding</span>
              <span>
                <input type='checkbox' name='reason' id='branding' />
              </span>
            </label>

            <label htmlFor='itservices'>
              <span>
                <img src={computing} width='80px' height='80px' alt='' />
              </span>
              <span>IT Services</span>
              <span>
                <input type='checkbox' name='reason' id='itservices' />
              </span>
            </label>
          </ContactFormCheckboxesWrap>
          <ContactFormInputsWrap>
            <label htmlFor='first-name'>Ονομα</label>
            <input
              type='text'
              id='first-name'
              name='first-name'
              placeholder='*Ονομα'
              required
            />
            <label htmlFor='last-name'>Επιθετο</label>
            <input
              type='text'
              id='last-name'
              name='last-name'
              placeholder='*Επιθετο'
              required
            />
            <label htmlFor='phone'>Τηλεφωνο</label>
            <input
              type='tel'
              id='phone'
              name='phone'
              placeholder='*Τηλεφωνο'
              required
            />
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              id='email'
              name='email'
              placeholder='*Email'
              required
            />
            <label htmlFor='message'>Μηνυμα</label>
            <textarea
              id='message'
              name='message'
              cols='30'
              rows='10'
              placeholder='*Μηνυμα'
              required
            ></textarea>
          </ContactFormInputsWrap>
          <div className='consent__wrap'>
            <p>
              ΤΑ ΣΤΟΙΧΕΙΑ ΠΟΥ ΘΑ ΜΑΣ ΣΤΕΙΛΕΤΕ ΘΑ ΤΗΡΗΘΟΥΝ ΜΕ ΕΜΠΙΣΤΕΥΤΙΚΟΤΗΤΑ
              ΚΑΙ ΔΕΝ ΘΑ ΔΗΜΟΣΙΟΠΟΙΗΘΟΥΝ ΜΕ ΟΠΟΙΟΔΗΠΟΤΕ ΤΡΟΠΟ ΣΕ ΤΡΙΤΟΥΣ. ΓΙΑ
              ΠΛΗΡΟΦΟΡΙΕΣ ΣΧΕΤΙΚΑ ΜΕ ΤΟΝ ΤΡΟΠΟ ΠΟΥ ΕΠΕΞΕΡΓΑΖΟΜΑΣΤΕ ΤΑ ΠΡΟΣΩΠΙΚΑ
              ΣΑΣ ΔΕΔΟΜΕΝΑ ΔΙΑΒΑΣΤΕ ΤΗΝ{' '}
              <LinkG to={privacyPolicyLink}>
                ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ
              </LinkG>
              .
            </p>
            <label className='consent__checkbox-label' htmlFor='consent'>
              <input type='checkbox' name='consent' id='consent' required />
              <span>ΣΥΜΦΩΝΩ</span>
            </label>
          </div>
          {!isPending && (
            <ContactFormSubmit type='submit'>Αποστολη</ContactFormSubmit>
          )}
          {isPending && (
            <ContactFormSubmit type='submit'>Αποστολη...</ContactFormSubmit>
          )}
        </ContactForm>
        <ToastContainer position='bottom-right' />
      </div>
    </ContactSection>
  );
}

const ContactSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .section-inner {
    width: 60%;
    @media screen and (max-width: 1106px) {
      width: 80%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .hidden {
    visibility: hidden;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.375rem;
  width: 100%;
  margin-top: 1rem;
  @media screen and (max-width: 1024px) {
    width: 90%;
    min-width: 18.75rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ContactFormCheckboxesWrap = styled.div`
  display: grid;
  column-gap: 3rem;
  row-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 608px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    padding: 0.5rem;
    border: 0.063rem solid transparent;
    transition-duration: 0.2s;
    &:hover {
      border: 0.063rem solid black;
    }
  }
`;

const ContactFormInputsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.75rem;
  row-gap: 1.375rem;
  width: 100%;
  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
  input {
    border: 0.063rem solid #000;
    padding: 1.25rem 1rem;
    color: #000;
    &::placeholder {
      text-transform: uppercase;
    }
  }

  textarea {
    border: 0.063rem solid #000;
    padding: 1.25rem 1rem;
    color: #000;
    font-family: inherit;
    resize: none;
    grid-column-end: span 2;
    &::placeholder {
      text-transform: uppercase;
    }
  }
  label {
    display: none;
  }
`;

const ContactFormSubmit = styled.button`
  position: relative;
  border: 0.063rem solid #000;
  padding: 1.25rem 1rem;
  color: #000;
  background-color: transparent;
  height: 4rem;
  width: 15.625rem;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  transition-duration: 0.2s;
  &:hover {
    color: #fff;
    background-color: #000;
  }
`;
