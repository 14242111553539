import React from 'react';
import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export default function CallToAction() {
  return (
    <CallToActionSection id='call-to-action'>
      <div className='section-inner'>
        <CallToActionLink to={'contact'} smooth={true} duration={700}>
          <span>Ξεκινήστε το δικό σας project</span>
        </CallToActionLink>
      </div>
    </CallToActionSection>
  );
}

const CallToActionSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
`;

const CallToActionLink = styled(LinkS)`
  cursor: pointer;
  font-size: 2.5rem;
  color: #fff;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  span {
    position: relative;
    transition: 0.5s;
    padding: 4px 0;
    display: block;
    &::after {
      content: '';
      background: #fff;
      width: 100%;
      height: 2px;
      position: absolute;
      top: 100%;
      left: 0;
      transition: transform 0.5s;
      transform: scaleX(0);
      transform-origin: left;
    }
    &:hover::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
`;
